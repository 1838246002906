import { Ref } from 'react';

//*******************************
//   ROUTES
//*******************************

export interface IGlobalProps {
  location: EPageLocation;
}

export enum EPageLocation {
  contact,
  events,
  home,
  medias,
  merch,
  music
}

//*******************************
//   COMMON
//*******************************

export interface ITabs {
  key: string;
  label: string;
}

export enum SocialIconType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok'
}

export type IconSize = 'tiny' | 'small' | 'medium' | 'large';

export interface SocialIcon {
  iconType: SocialIconType;
  size: IconSize;
  linkTo: string;
}

//*******************************
//   EVENTS
//*******************************

export interface IEvent {
  bands: string;
  date: string;
  dateEnd: string;
  dateParsed: Date;
  doorTickets: boolean;
  mapLink: string;
  meta: { [lang: string]: IEventMeta };
  onPlaceTicketLink: string;
  place: string;
  soldOut: boolean;
  virtualTicketsLink: string;
}

export interface IEventBand {
  name: string;
  link: string;
}

export interface IEventMeta {
  cityState: string;
  eventTitle: string;
  note?: string;
}

//*******************************
//   MEMBERS
//*******************************

export interface IMember {
  id: string;
  mainImage: string;
  altImgText: string;
  nickname: string;
  name: string;
  socialLinks: SocialIcon[];
  textLocalKey: string;
}

//*******************************
//   SLIDER
//*******************************

export type TSlideBgType = 'cover' | 'contain';

export interface ISliderImage {
  linkHref: string;
  linkText: string;
  src: string;
  text: string;
  title: string;
  type: string;
  bgType?: TSlideBgType;
  filter?: string;
}

export interface ISliderProps {
  slideImage: Ref<HTMLDivElement>;
  slideFeature: Ref<HTMLDivElement>;
  slideFeatureBtn: Ref<HTMLAnchorElement>;
  slideFeatureText: Ref<HTMLHeadingElement>;
  slideFeatureTitle: Ref<HTMLDivElement>;
  slideIframe: Ref<HTMLDivElement>;
  images: ISliderImage[];
}
